:root{
	//Standard white and grey theme
	--theme-1:#FFF;
	--theme-1-light:#FFF;
	--theme-2:#FFF;
	--theme-3:#f3f4f7;
	--theme-4:#848A90;
	--theme-5:#FFF;
	--theme-1-text:#000;
	--theme-2-text:#000;
	--theme-3-text:#000;
	--theme-4-text:#000;
	--theme-5-text:#000;
	--theme-chart-1:#0795d3; //blue
	--theme-chart-2:#be1337;//red
	--theme-chart-3:#da8707;//orange
	--theme-chart-4:#ffa600; //yellow
	--theme-chart-5:#00b050;//green
	--theme-chart-6:#03A9F4;
	--theme-chart-7:#9e5bb3;
	--theme-chart-8:#FF5722;
	--theme-chart-9:#0365a3; //blue
	--theme-chart-10:#fe4377;
	/*
	--theme-1:#F1F6F4;
	--theme-2:#00bcd4; //#F2AE72;
	--theme-3:#ED8142;
	--theme-4:#848A90;
	--theme-5:#3C4F5B;
*/
}

$border-color: #CCC;
$list-background: #FFF;
$list-text: #333;//rgb(56, 85, 99);
$theme-1:var(--theme-1);
$theme-1-light:var(--theme-1-light);
$theme-2:var(--theme-2);
$theme-3:var(--theme-3);
$theme-4:var(--theme-4);
$theme-5:var(--theme-5);
$theme-1-text:var(--theme-1-text);
$theme-2-text:var(--theme-2-text);
$theme-3-text:var(--theme-3-text);
$theme-4-text:var(--theme-4-text);
$theme-5-text:var(--theme-5-text);


body .report-wrapper{
	height:100%;
}


#report-inner-wrapper{
	height:100%;
	width:100%;
}

.date-range-picker{
	border: 1px solid rgba(84, 95, 95,.6);
	border-radius: 6px;
}

.date-range-quick-date div{
		z-index:5000; //some components of the date picker are too high
}

.date-range-picker i{
	padding:0 8px;
}
.date-range-picker .MuiButton-label{
	font-size:1.1em;
}


body #public-report-wrapper{
	display: flex;
	overflow: hidden;
	flex-direction:row;
	position: absolute;
	right: 0;
	left: 0;
	top:0;
	bottom:0;
	height:100%;
}
body #console-report-wrapper{
	display: flex;
	overflow: hidden;
	flex-direction:row;
	height:100%;
	width:100%;
}

body #report-gallery{
	overflow:auto;
}

body #console-report-wrapper,body #public-report-wrapper{
	/*
	.report-body-wrapper{
		//background:$theme-3;//#f6f6f6;
		//background-image: linear-gradient(to bottom, $theme-3, rgba(220,220,220,1));
	} //keep the report main background consistent
	*/
	/* This is necessary because otherwise some components, like PieChart can't calculate their own height*/
	.component-wrapper{
		height:100%;
		max-height:1000px;
	}
	.table thead th {
		border-top: 0;
	}

	.report-body,.react-grid-layout{
		background:$theme-1;
		color:$theme-1-text;
	}
	.report-card{
		border:1px solid #CCC;
		background:$theme-1-light;
	}
	.report-card .report-card-header{
    border-bottom: 1px solid #CCC;
    background: $theme-1;
    color: $theme-3-text;
	}
	.report-card .report-card-header{
		 .MuiTypography-colorTextSecondary, .MuiCardHeader-title{
			 color: $theme-3-text;
		 }
	}
	.report-scorecard-stat{
		color: $theme-1-text;
	}

	.console-report-col{
		flex: 1;
		overflow:auto;
	}

	.report-card{
		padding:0px;
		margin:0px 5px 20px 5px;
		border-radius:5px;
	}
	.report-card-header{
		border-bottom:1px solid $border-color;
	}
	.report-card-header .MuiCardHeader-title{
		font-size:1.3em;
		font-weight:400;
	}
	.report-card.FraktureReportTable .MuiCardContent-root{
		padding:0;
	}
	.report-card.FraktureReportTable .MuiCardContent-root .table{
		margin-bottom:0;
	}
	.report-demo-notice{
		width:100%;
		margin:0;
		display:flex;
		justify-content: space-between;
		align-items:flex-end;
	}

	.frakture-report.frakture-report-v1{
		height:auto;
		width:100%;
	}
	.frakture-report{
		padding:0px;
		left:0px;
		position:relative;
		right:0;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
    height: 100%;

		.frakture-report-header{
			border-bottom:1px solid #DDD;
			padding:10px;
			background:#FFF;
			min-height:65px;
		}
		.report-body-wrapper{
			display:flex;
			justify-content:space-between;
			overflow: auto;
			height: 100%;
		}
		.report-center-wrapper{
			width:100%;
			margin-left:auto;
			margin-right:auto;
			padding:10px;
		}
		.report-filter-drawer{
			padding:10px;
			width:300px;
			border-left:1px solid #DDD;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
			z-index: 1000;
    	position: absolute;
    	right: 0;
    	top: 84px;
    	bottom: 0;
		}

		.report-body,.react-grid-layout{
			width:100%;
			max-width:1400px;
			margin: 0px auto 0px auto;
			border:1px solid #ddd;
			padding:15px 10px;
			border-radius:5px;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
		}
		.frakture-report-footer{
			width:100%;
			padding:7px 15px;
			display:flex;
			justify-content: space-between!important;
			position:relative;
			bottom:0;
			right:0;
			background:#EEE;
			border-top:1px solid #DDD;
		}
		.report-bar-chart,.report-vertical-bar-chart,.report-stacked-bar-chart{
			min-height:300px;
		}
		.report-bar-chart .recharts-cartesian-grid{
			opacity:40%;
		}


		.MuiInput-underline:before{
			border:0;
		}

		.report-row{
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
		}
		.report-label-text{
			padding-left:20px;
			text-align: center;
		}
		.report-label-demo{
			font-size:1.4em;
			color:#C33;
			font-weight:400;
		}
		.report-large-select .MuiInputBase-input,.report-large-select .MuiSvgIcon-root{
			font-size:24px;
		}
		.report-large-select .MuiSelect-icon{
			top:0;
		}
		.report-item-label{
			//color:#555;
			font-weight:400;
		}
		.report-item-header{
			color: var(--theme-3-text);
			font-size: 1.3em;
    		font-weight: 400;
		}

		.report-scorecard-stat{
			font-size:2.4em;
		}
		
		.resize2{
			font-size: 20px;
		  font-size: 2.0vw;
		}
		
		.resize3{
			font-size: 30px;
		  font-size: 3.5vw;
		}
		
		.resize4{
			font-size: 40px;
		  font-size: 4vw;
		}

		@media (max-width: 768px){
			.react-grid-layout{margin:0px 0px;} /* Allow for double size header lines */
			.report-label{
				text-align:center;
			}
		}
	}
	@media (max-width: 768px){
		.frakture-report{padding:0px;}
	}


	.frakture-report .report-sidebar-button{
		margin:0 10px;

	}

	.report-account-name{
		padding:0 30px;
		vertical-align: middle;
		font-size: 25px;
	  font-size: 2.5vh;
	}

	.report-label{
		font-size: 2.4em;
	    font-weight: 500;
	}
	.report-logo,.report-label,.report-filters{
		height:100%;
	}
	.frakture-report-custom-tooltip{
		background:#FFF;
		border:1px solid #EEE;
		border-radius:5px;
		padding:10px;
	}


	.frakture-report-editing .react-resizable{
		border:1px solid #BBB;
	}
}
.copy-text{
	display:flex;
	justify-content: space-between;
	align-items: center;
	min-width:500px;
	white-space: nowrap;
}
.copy-text form{
	width:90%;
}
.copy-text .copy-button{
	width:50px;
}

.copy-text input{
	width:100%;
	font-size:1.2em;
	background:#eee;
	border:1px solid #ccc;
}
