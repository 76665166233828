#source_code_parsing .MuiTableCell-paddingNone {
    max-width: 60px;
    overflow: auto;
    border: 1px solid #DDD;
    padding: 3px;
}

#source_code_parsing {
  table:first-child {
    tr {
      td:first-child, th:first-child {
        background-color: '#f5f5f5';
        position: 'sticky';
        left: 0;
        z-index: 999;
      }
      th:first-child {
        z-index: 9999;
      }
    }
  }
}
