input.frakture-save-on-blur {
  border-top: none black;
  border-left: none black;
  border-right: none black;
  border-bottom: 1px solid lightgray;
  padding-bottom: 4px;
  margin-bottom: 3px;
  width: 100%;
  outline: none;
}

input.frakture-save-on-blur:hover, input.frakture-save-on-blur:focus {
  border-bottom: 1px solid #5bc0de;
}

textarea.frakture-save-on-blur {
  border-bottom: none black;
  border-left: none black;
  border-right: none black;
  border-top: 1px solid lightgray;
  padding-bottom: 4px;
  margin-bottom: 3px;
  max-height: 300px;
  width: 100%;
  outline: none;
}

textarea.frakture-save-on-blur:hover, textarea.frakture-save-on-blur:focus {
  border-top: 1px solid #5bc0de;

  border-bottom: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  resize: vertical;
}
