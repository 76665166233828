div.frakture-search-box div {
	width: 50%;
}

div.frakture-search-box input {
  border-top: none black;
  border-left: none black;
  border-right: none black;
  border-bottom: 1px solid lightgray;
  padding-bottom: 4px;
  margin-bottom: 3px;
  outline: none;
}

div.frakture-search-box input:hover , div.frakture-search-box input:focus {
  border-bottom: 1px solid #5bc0de;
}
