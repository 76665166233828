/*Tabs Styles*/

.tab {
  min-width: 10px !important;
  font-size: 14px !important;
}

.tab-icon > span {
  @include display-flex();
}

.jr-tabs-classic {
  position: relative;

  & .jr-tabs-content {
    position: relative;
    padding-top: 30px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 1px;
      border-bottom: solid $border-width $border-color;
    }
  }
}

.jr-tabs-classic-no-border {
  & .jr-tabs-content:before {
    display: none;
  }
}

.jr-tabs-up {
  position: relative;
  & .jr-tabs-label {
    min-width: 70px;
  }

  @media screen and (min-width: 576px) {
    margin-top: -40px;
    margin-right: 30px;
    margin-left: 100px;
  }
}

.jr-tabs-pills-ctr {
  @include justify-content(center);

  & .nav-link {
    padding: 2px 14px;
  }

  & .nav-pills .nav-link {
    @include border-radius($border-radius-sm);
  }
}