:root{
	//Standard white and grey theme
	--theme-1:#FFF;
}

$theme-1:var(--theme-1);
//$sidebar-background:#23312c;
//$sidebar-background:#13211c;

/* cyan */
/*
$sidebar-background:#00bcd4;
$sidebar-color:#FFF;
$sidebar-border-color:#DDDDDD;
*/



/*Grey*/

//$sidebar-background:#F4F4F7;
//$list-active: #C7F2F2;
//$list-active:lighten(#00d9ff,40%);
$list-active:#00b8e5;
$list-2-active:lighten(#00b8e5,50%);

$sidebar-background:#FFFFFF;
$sidebar-color:#595959;
$sidebar-border-color:#DDDEDF;

//$list-active-color: #111111;
$list-active-color: #FFF;
$list-2-active-color: inherit;


/*Purple*/
//background-color: #00bcd4!important;
//$sidebar-background:darken(#bb49ea,35%);
//$sidebar-color:#E3E3E3;
//$sidebar-border-color:#AAA;
//$list-active: darken(#bb49ea,40%);
//$list-active-color: #FFF;

$sidebar-weight:420;
$sidebar-min-height:30px;

$list-background: #FFF;
$list-text: #333;//rgb(56, 85, 99);

$category-warehouse: #d0d0d0; //grey
$category-transactions: #f89800; //yellow
$category-messages:#00b8e5; //blue (dark)
$category-attribution: #6ac481; //green
$category-origin: #ff5722; //orange
$category-segments: #bb49ea; //purple

h1{
	font-size:2.2em;
}

#body, body#body .app-job-wrapper{
	a{
		color:#2c76ac;
	}

	.status-ok {
		background-color: #F8FFF8 !important;
	}
	.status-unscheduled {
		background-color: #efefef !important;
	}
	.status-pending,.status-in_progress {
		background-color: #e8eaf6 !important;
	}
	.status-sent_to_queue {
		background-color: #dbebc6 !important;
		background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 50%, #fff 50%, #fff 75%, transparent 75%, transparent);
		background-image:      -o-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 50%, #fff 50%, #fff 75%, transparent 75%, transparent);
		background-image:         linear-gradient(45deg, #fff 25%, transparent 25%, transparent 50%, #fff 50%, #fff 75%, transparent 75%, transparent);
		-webkit-background-size: 40px 40px;
						background-size: 40px 40px;
	}
	.status-killing,.status-kill_sent {
		background-color: #fcbeac !important;
		background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 50%, #fff 50%, #fff 75%, transparent 75%, transparent);
		background-image:      -o-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 50%, #fff 50%, #fff 75%, transparent 75%, transparent);
		background-image:         linear-gradient(45deg, #fff 25%, transparent 25%, transparent 50%, #fff 50%, #fff 75%, transparent 75%, transparent);
		-webkit-background-size: 40px 40px;
						background-size: 40px 40px;
	}
	.status-started {
		background-color: lighten(#82d4a1,20%) !important;
	}
	.status-paused {
		background-color: #f4e4c8 !important;
	}
	.status-complete {
		background-color: #efefef !important;
	}
	.status-error {
		background-color: #fcbeac !important;
	}
	.status-auth_error{
		background-color: #beacfc !important;
	}

	.status-text-pending {color: #bdf2fe;}
	.status-text-sent_to_queue {color: #dbebc6;}
	.status-text-killing,.status-text-kill_sent {color: #fcbeac;}
	.status-text-started {color: #c3dea0;}
	.status-text-paused {color: #f4e4c8;}
	.status-text-complete {color: #a2a2a2;}
	.status-text-error {color: #fcbeac;}
}




#background{
	position:absolute;
	opacity:.8;
	width:100%;
	height:100%;
	top:0;
	left:0;
	z-index:-100;
	//background-image: linear-gradient(to bottom, #f3f4f7, #dcdcdc);
	//background:url(https://www.frakture.com/_ipx/w_2048,q_100/https%3A%2F%2Fimages.ctfassets.net%2Fcrj7v58ikuy1%2F4y9irr2nDVADYhXXXnZ4tP%2Fb2aa44dc0d9569794cae364f548cd6ee%2FFrakture_BotDesigns_WebsiteHero_v1-2x.jpg?url=https%3A%2F%2Fimages.ctfassets.net%2Fcrj7v58ikuy1%2F4y9irr2nDVADYhXXXnZ4tP%2Fb2aa44dc0d9569794cae364f548cd6ee%2FFrakture_BotDesigns_WebsiteHero_v1-2x.jpg&w=2048&q=100);
	background: rgb(130,212,161);
	background: linear-gradient(315deg,rgba(130,212,161,1) 0%, rgba(0,217,255,1) 100%);
}

#sidebar,#sidebar .MuiListItemText-primary,
#sidebar .sidebar-item,
#report-list-deployed .MuiListItemText-primary
{
	font-size: 14px;
  line-height: 1.0;
	font-weight: $sidebar-weight;
}

#sidebar .sidebar-item{
	min-height:$sidebar-min-height;
	padding:10px 14px;
	margin: 6px;
  border-radius: 10px;
}

#sidebar .MuiListSubheader-root.sidebar-item{
	padding:12px;
	font-weight:550;
}

#sidebar{
	display: flex;
	//height: 100%; //no height means it should go the whole height
	//overflow: visible; //this means long sidebars won't scroll
	border-right: 1px solid;
	border-color: $sidebar-border-color;

	font-weight: $sidebar-weight;
	background:$sidebar-background;
	color:$sidebar-color;
	width: 60px;
	flex: 0 0 60px;
	overflow-x: hidden;
	.sidebar-header{
		display:flex;
		flex-direction:column;
		justify-content:center;
		background:$sidebar-background;
		color:$list-active-color;
		min-height:65px;
		border-bottom:1px solid $sidebar-border-color;
	}

	.logo-large{
		width:190px;
		padding: 6px;
		height: auto;
		display:none;
	}
	.logo-small{
		width:60px;
		padding: 6px;
		display:block;
	}
	.MuiDivider-root{
		border-color:$sidebar-border-color;
	}
	.sidebar-link{
		color:$sidebar-color;
		text-decoration:none;
	}

	.sidebar-collapse-button{
		/*border: 1px solid;
	  border-color: $sidebar-border-color;
	  border-radius: 20px;
	  background-color: $sidebar-background;
		*/
	  display: flex;
	  align-items: center;
	  justify-content: center;

	  cursor: pointer;
	  padding: 3px 9px;
	}
	.sidebar-account-name{
		display:none;
	}

	.sidebar-content{
		width:100%;

		.menuAccordion:before{
			height:0;
		}
		.menuAccordion{

			padding:0;
			margin:0;
			box-shadow:none;
			.MuiAccordionSummary-root{
				min-height:$sidebar-min-height;
			}
			.MuiAccordionSummary-content,.MuiAccordionSummary-root,.MuiAccordionDetails-root{
				margin:0;
				padding:0;
			}
			.MuiAccordionDetails-root{
				.MuiListItemButton-root{
					padding:8px 0px 8px $sidebar-min-height;
				}
			}

	}

	.sidebar-item,.account-picker__placeholder{
		color:$sidebar-color;
	}

	#account-picker div,.MuiListItemButton-root,.MuiListSubheader-root,.sidebar-item{
		background:$sidebar-background;
		color:$sidebar-color;
		font-weight: $sidebar-weight;
	}
	.sidebar-icon{
		padding-left:6px;
		font-size:14px;
		min-width: 36px;
		margin:auto;
		color: inherit;
	}
	.search-icon{
		font-size:14px;
		margin:auto;
		color: inherit;
	}

	.MuiListItemButton-root:hover,.sidebar-item:hover{
		background-color:lighten($list-active,50%);
	}
	
	.Mui-selected .MuiListItemText-primary{
		font-weight:650;
		color:$list-active-color;
	}
	.Mui-selected{
		background:$list-active !important;
	}
	
	.menuAccordion.sidebar-item:hover{
			color:inherit;
			background:inherit;
		}
	}
}
#sidebar.mobile-drawer{
	flex:1 1 !important;
}

#sidebar.is-expanded{
	.sidebar-header{
		flex-direction:row;
	}
	width: 220px;
	//overflow:visible;
	flex: 0 0 220px;
	.logo-small{
		display:none;
	}
	.logo-large{
		display:block;
	}
	.sidebar-account-name{
		display:block;
	}
}

#sidebar,#sidebar .sidebar-collapse-button{
	transition: flex .1s cubic-bezier(0, 0, 0.35, 1), width .1s cubic-bezier(0, 0, 0.35, 1),left .1s cubic-bezier(0, 0, 0.35, 1);
	animation-name: slideInFromLeft;
	animation-duration: 1s;
	animation-timing-function: cubic-bezier(0, 0, 0.35, 1);
	animation-delay: 0s;
	animation-iteration-count: 1;
	animation-direction: normal;
}


.account-picker-dialog .MuiDialog-container{
	align-items:start;
}

.report-label-demo{
	font-size: .8em;
	padding:0 10px;
	color: #FFF;
	background: #EA9717;
	font-weight: 400;
	text-transform:uppercase;
	float:right;
	border-radius:5px;
}

.gallery{
	.gallery-row-wrapper{
		display:flex;
		flex-wrap:wrap;
	}
	.gallery-item{
		width: 236px;
		margin:12px;
	}
	
}

.card-heading{
	font-weight:450;
	font-size:16px;
}
#sidebar2{
	flex:0 0 350px;
	background:#FFF;
	//border-right:1px solid $sidebar-border-color;
	border-radius:0;
	color:$list-text;
	//height:100%; //should go the whole height available, not be limited to 100%
	overflow: auto;
	.MuiListItemButton-root:hover,.sidebar-item:hover,.sidebar-item:hover .flex-card{
		text-decoration:none;
		background-color:lighten($list-active,50%);
		//color:$list-2-active-color;
	}

	.sidebar-item.selected,
	.Mui-selected{
		text-decoration:none;
		background-color:$list-2-active;
		color:$list-2-active-color;
	}
	&.sidebar2-wide{
		flex:0 0 400px;
	}
	.card-heading{
		color:$list-text;
	}

	#report-list-deployed > #report-list{
		max-height:320px;
		overflow-y:auto;
	}
	#report-list{
		color:$list-text;
		padding:0;
	}
	#report-list > .report-directory-header{
		border-left:3px solid #FFF;
	}
	#report-list > .folder-is-open{
		border-left:3px solid $list-2-active;
	}
	#report-list .folder-is-open.report-directory-header .MuiTypography-body1{
		font-weight: 400;
	}

	#report-list .report-directory-header .MuiTypography-body1{
		font-size:15px;
		font-weight:300;
	}


	#report-list > div{
		border-left:3px solid transparent;
	}

	#report-list .MuiListItemIcon-root,#report-list .MuiTypography-colorTextSecondary{
		color:inherit;
	}
	#report-list .MuiChip-root {
		color:$list-text;
		border: 1px solid $list-text;
		margin:2px;
		padding:2px;
	}


	#report-list .report-subdirectory{
		//max-height:400px;
		overflow:auto;
		padding-left:20px;
	}

	.report-sidebar-item.active .report-sidebar-details{
		display:block;
	}
	.report-sidebar-details{
		display:none;
		background:#FFF;
		z-index:1000;
		position:fixed;
		left:58px;
		top:0;
		bottom:0;
		min-width:200px;
	}
	.report-sidebar-details .report-list-item:hover{
		background:#EEE;
	}

	.sidebar-header{
		min-height:65px;
		border-bottom:1px solid $sidebar-border-color;
		.header{
			font-size:1.5em;
			font-weight:600;
			padding:10px;
			display:flex;
			align-items: flex-start;
			justify-content:space-between;
		}
		.sub-heading{
			padding:10px;
		}
		.sidebar-close{
			padding:0px 5px;
			margin:0;
		}
	}
	.sidebar-item{
		padding:10px 12px 3px 12px;
		border-bottom:1px solid $sidebar-border-color;
		border-right:4px solid transparent;
		.primary{
			font-size:15px;
			font-weight:500;
			display:flex;
			justify-content: space-between;
			span{
				overflow:hidden;
				//text-wrap: nowrap;
			}
		}
		
		.secondary{
			font-size:12px;
			padding:10px 0px;
			display:flex;
			justify-content: space-between;
		}
		.footer{
			padding:16px 0px 0px 0px;
			display:flex;
			justify-content: space-between;
			button{
				padding:0;
			}
		}
	}

	.sidebar-item.selected{
		border-right:4px solid $list-active;
	}
}

$default-shadow:#000;

@mixin boxShadow($color) {
	//box-shadow:change-color($color, $alpha: 0.15) 0px 15px 25px, change-color($color,$alpha:.05) 0px 5px 10px;
	//box-shadow:0px 2px 1px -1px change-color($color, $alpha: 0.2), 0px 1px 1px 0px change-color($color, $alpha: 0.14), 0px 1px 3px 0px change-color($color, $alpha: 0.12);
	//box-shadow:0px;
	box-shadow: rgba(159, 162, 191, 0.18) 0px 9px 16px, rgba(159, 162, 191, 0.32) 0px 2px 2px;
	/*
	&:hover{
		box-shadow: change-color($color, $alpha: 0.35) 0px 15px 25px, change-color($color,$alpha:.25) 0px 5px 10px;
		box-shadow:0px 4px 3px 1px change-color($color, $alpha: 0.2), 0px 3px 3px 0px change-color($color, $alpha: 0.14), 0px 3px 6px 0px change-color($color, $alpha: 0.12);
	}
	*/
}

.app-main-content-body{
	background:#FFF;
	height: 100%;
    display: flex;
    flex-direction: column;
}

.app-main-content-header{
	border-bottom:1px solid #DDD;
	padding:10px 10px 10px 0px;
	background:#FFF;
	min-height:65px;
	h2{
		font-size:22px;
		font-weight:500;
		padding:0px 20px;
		margin:5px 0px;
	}
	.primary{
		display:flex;
		justify-content: space-between;
		align-items: center;
	}
	.secondary{
		font-size:12px;
		padding:10px 0px;
		display:flex;
		justify-content: space-between;
	}
	.footer{
		padding:0px;
		display:flex;
		justify-content: space-between;
		button{
			padding:0;
		}
	}
}


.hero-card .card-header:first-child{
	border-top-left-radius: 12px;
	border-top-right-radius:12px;
}


//box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);

.hero-card{
	//transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	@include boxShadow($default-shadow);
  border-radius: 12px;
	border: 1px solid $sidebar-border-color;
	opacity:1;
	.card-header{
		background:inherit;
		font-size:1.2em;
		font-weight:550;
	}
	.card-body{
		height:420px;
		overflow-y:scroll;
	}
}
.flex-card{
	transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	@include boxShadow($default-shadow);
}

.frakture-data-table{
	background:#FFF;
	height:100%;
	min-height:300px;
}
.frakture-table{
	.frakture-table-header{
		padding:8px 16px;
		display:flex;
		justify-content: space-between;
		align-items: baseline;
	}
}

.card-body{
	&:has(.frakture-table){
		padding:0;
	}
	.frakture-table{
		border:0;
		padding:0px;
		margin:0px;
	}
}
.MuiCardHeader-title{
	font-weight:550;
}

.MuiCardHeader-root{
	padding:16px 16px 8px 16px;
}
.MuiCardHeader-subheader{
	padding:16px 5px 5px 0px;
}


/********* messages *******/
.message-filters{
	flex-grow:1;
	padding:0px 20px;
}
.message-filters form{
	display: flex;
    justify-content: space-between;
    align-items: center;
	width:100%;
}
.message{
	padding: 18px;
	margin:10px;
	background: #FFF;
	border-radius: 15px;
}
.message-html{
	border:1px solid #DDD;
	margin:10px;
	padding:10px
}

.message-text{
	border:1px solid #DDD;
	background:#FCFCFC;
	padding:20px;
	margin:20px;
	white-space: pre-wrap;
}

.message-statistic{
	display:flex;
	justify-content:space-between;
	width:100%;
	.label{
		font-size:1.4em;
	}
	.value{
		font-size:1.4em;
	}
}


/********* channels ************/

.revenue{
	color:#f89800;
	font-size:1.2em;
	font-weight:500;
}
.attributed_revenue{
	color:#5eb073;
	font-size:1.2em;
	font-weight:500;
}
.attributed_revenue::before {
	content: 'attr. ';
	//display:block;
	color:#5eb073;
	font-size:.7em;
	font-weight:500;
  }

.channel-icon{
	font-size:1.2em;
}

$channel-color:#FFF;
$channel-border-radius:5px;

.channel-email{border-radius:$channel-border-radius;color:$channel-color;background-color:rgba(0,217,255,1)}
.channel-google_ad{border-radius:$channel-border-radius;color:$channel-color;background-color:rgba(130,212,161,1)}
.channel-facebook,
.channel-facebook_ad{border-radius:$channel-border-radius;color:$channel-color;background-color:#00b8e5}
.channel-sms{border-radius:$channel-border-radius;color:$channel-color;background-color:#beacfc;}
 




	

/*
.category-warehouse{box-shadow:$category-warehouse $default-shadow;
	&:hover{ box-shadow: $category-warehouse 0px 0.5rem 1.2rem;}
}

.category-messages{@include boxShadow($category-messages)}
.category-alt_channels{@include boxShadow($category-messages)}
.category-transactions{@include boxShadow($category-transactions)}
.category-sourcecoding{@include boxShadow($category-attribution)}
.category-origin{@include boxShadow($category-origin)}
.category-segments{@include boxShadow($category-segments)}
.category-allegiance{@include boxShadow($category-segments)}
*/
