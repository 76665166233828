
input.frakture-label-edit {
  padding-left: 8px;
  border: none;
  outline: none;
  resize: none;
	padding-bottom: 1px;
  width: 100%;
  font-weight:500;
  font-size:1.2em;
}

input.frakture-label-edit:hover, input.frakture-label-edit:focus {
  border-color: #CDCFD2;
  border-style: solid;
  border-width: 0px;
	padding-bottom: 0px;
	border-bottom-width: 1px;
  /* modify the position and margin to keep consistent when border applied */
  /* left: -1px; */
  position: relative;
}
